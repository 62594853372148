import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import NewsCard from './NewsCard'
import StrapiNewsCard from './StrapiNewsCard'

const Navbar = () => (
      <StaticQuery
        query={graphql`
          {
            featured: allStrapiBlogPost(
              sort: {order: DESC, fields: publishedAt}
              filter: {featured: {eq: true}}
            ) {
              nodes {
                id
                title
                description
                publishedAt
                expiresAt
                externalLink
                featured
                autoArchive
                heroImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
            standard: allStrapiBlogPost(
              sort: {order: DESC, fields: publishedAt}
              filter: {featured: {eq: false}}
            ) {
              nodes {
                id
                title
                description
                publishedAt
                expiresAt
                externalLink
                featured
                autoArchive
                heroImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
            pinned: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}, pinned: {eq: true}}}) {
              edges {
                node {
                  excerpt(pruneLength: 400)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    description
                    thumbnail {
                      childImageSharp {
                        fluid (maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    templateKey
                    date(formatString: "MMMM DD, YYYY")
                    expires(formatString: "MMMM DD, YYYY, h:mm:ss a")
                  }
                }
              }
            }
            regular: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}, pinned: {ne: true}}}) {
              edges {
                node {
                  excerpt(pruneLength: 400)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    description
                    thumbnail {
                      childImageSharp {
                        fluid (maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    templateKey
                    date(formatString: "MMMM DD, YYYY")
                    expires(formatString: "MMMM DD, YYYY, h:mm:ss a")
                  }
                }
              }
            }
          }
        `}
        render={ function(data) {
          let strapiPosts = []
          // sort featured first
          if(data.featured !== null) {
            const {nodes: featured} = data.featured
            const {nodes: standard} = data.standard
            strapiPosts = featured.concat(standard)
          } else {
            const {nodes: standard} = data.standard
            strapiPosts = standard
          }
          //filter expired posts
          const filteredStrapiPosts = strapiPosts.filter((value, index, array) => {
            let expired = false
            if(value.expiresAt !== null) {
              if(new Date(value.expiresAt).getTime() < new Date().getTime()) {
                expired = true
              }
            }
            if(value.autoArchive === true) {
              const d = new Date()
              if(new Date(value.publishedAt).getTime() < d.setDate(d.getDate() - 180)) {
                expired = true
              }
            }

            return !expired
          })

          let renderedFilteredStrapiPosts = filteredStrapiPosts.slice(0,3)

          let posts = []
          if(data.pinned !== null) {
            const {edges: pinned} = data.pinned
            const {edges: regular} = data.regular
            posts = pinned.concat(regular)
          } else {
            const {edges: regular} = data.regular
            posts = regular
          }

          const filteredPosts = posts.filter((value, index, array) => {
            if(value.node.frontmatter.expires === null) {
              return true;
            } else if(new Date(value.node.frontmatter.expires).getTime() >= new Date().getTime()) {
              return true;
            } else {
              return false;
            }
          })

        return (
          <>
            {renderedFilteredStrapiPosts.map((post) =>(
              <StrapiNewsCard key={post.id} post={post} />
            ))}
            {filteredPosts.slice(0,3-renderedFilteredStrapiPosts.length).map(({ node: post }) =>(
              <NewsCard key={post.id} post={post} />
            ))}
            
          </>
        )}
      }
      />
)

export default Navbar
