import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'
import { HTMLContent } from '../components/Content'

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { markdownRemark: page } = data

  return (
      <HomePageTemplate
        content={page.html}
        contentComponent={HTMLContent}
        title={frontmatter.title}
        welcomeMessage={frontmatter.welcomeMessage}
        welcomeAside={frontmatter.welcomeAside}
      />
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    })
  }),
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`